import React from 'react';

export const Footer = () => (
  <footer className="bg-black-300 w-full py-6 px-4 mt-32 ">
    {/* <div className="px-4 pt-8 pb-4 border-t -mx-4 border-black-400">
      <div className="max-w-xl mx-auto">
        <h2 className="text-xl text-left inline-block font-semibold text-black-800">
          Join My Newsletter
        </h2>
        <p className="text-black-700 text-xs pl-px">
          Latest posts and updates delivered to your inbox.
        </p>
        <form action="#" className="mt-2">
          <div className="flex items-center">
            <input
              type="email"
              disabled
              placeholder="Coming soon!"
              className="w-full px-2 py-4 mr-2 bg-black-100 shadow-inner rounded-md border border-black-400 focus:outline-none"
              required
            />
            <button
              disabled
              className="bg-blue-600 text-gray-200 px-5 py-2 rounded shadow whitespace-no-wrap"
              style={{
                marginLeft: '-7.8rem',
              }}
            >
              sign Up
            </button>
          </div>
        </form>
      </div>
    </div> */}
    <div className="flex flex-col items-center justify-between my-4 px-4 max-w-xl mx-auto">
      <div className="flex items-center justify-center mb-5">
        <a href="mailto:merlin@level30wizards.com">
          <svg
            fill="none"
            className="h-6 w-6 text-blue-600 mr-6"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
        </a>
        <a href="https://www.linkedin.com/company/18680613/">
          <svg
            className="h-6 w-6 fill-current text-blue-600 mr-6"
            viewBox="0 0 512 512"
          >
            <path d="M444.17,32H70.28C49.85,32,32,46.7,32,66.89V441.61C32,461.91,49.85,480,70.28,480H444.06C464.6,480,480,461.79,480,441.61V66.89C480.12,46.7,464.6,32,444.17,32ZM170.87,405.43H106.69V205.88h64.18ZM141,175.54h-.46c-20.54,0-33.84-15.29-33.84-34.43,0-19.49,13.65-34.42,34.65-34.42s33.85,14.82,34.31,34.42C175.65,160.25,162.35,175.54,141,175.54ZM405.43,405.43H341.25V296.32c0-26.14-9.34-44-32.56-44-17.74,0-28.24,12-32.91,23.69-1.75,4.2-2.22,9.92-2.22,15.76V405.43H209.38V205.88h64.18v27.77c9.34-13.3,23.93-32.44,57.88-32.44,42.13,0,74,27.77,74,87.64Z" />
          </svg>
        </a>
        <a href="https://twitter.com/level30wizards">
          <svg
            className="h-6 w-6 fill-current text-blue-600"
            viewBox="0 0 512 512"
          >
            <path d="M496,109.5a201.8,201.8,0,0,1-56.55,15.3,97.51,97.51,0,0,0,43.33-53.6,197.74,197.74,0,0,1-62.56,23.5A99.14,99.14,0,0,0,348.31,64c-54.42,0-98.46,43.4-98.46,96.9a93.21,93.21,0,0,0,2.54,22.1,280.7,280.7,0,0,1-203-101.3A95.69,95.69,0,0,0,36,130.4C36,164,53.53,193.7,80,211.1A97.5,97.5,0,0,1,35.22,199v1.2c0,47,34,86.1,79,95a100.76,100.76,0,0,1-25.94,3.4,94.38,94.38,0,0,1-18.51-1.8c12.51,38.5,48.92,66.5,92.05,67.3A199.59,199.59,0,0,1,39.5,405.6,203,203,0,0,1,16,404.2,278.68,278.68,0,0,0,166.74,448c181.36,0,280.44-147.7,280.44-275.8,0-4.2-.11-8.4-.31-12.5A198.48,198.48,0,0,0,496,109.5Z" />
          </svg>
        </a>
      </div>

      <p className="inline-flex justify-center items-end text-black-500">
        by @{' '}
        <a
          target="_blank"
          href="https://level30wizards.com"
          title="Level30Wizards"
          aria-label="Level30Wizards"
          style={{
            minWidth: '10rem',
            height: '100%',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 316 93"
          >
            <path
              stroke="#08143F"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeWidth="16"
              d="M85.07 66.06v-5.93H70.04v24.16h15.03v-5.93h-7.52v-3.19h7.52v-5.93h-7.52v-3.18h7.52zm-43.62 9.09v-5.93h-7.52v-3.16h7.52v-5.93H26.42v9.09h0v5.93h0v9.14h15.03v-5.93h-7.52v-3.21h7.52zm54.52 3.21V60.18h-7.51v24.1h15.03v-5.92h-7.52zm-80.45 0V60.18H8v24.1h15.03v-5.92h-7.51zm43.88 5.93l8.94-24.16h-7.23l-5.37 14.5-5.37-14.5h-7.22l8.94 24.16h7.3zm136.16 0h-7.52v-24.1h7.52v24.1zm82.46-5.93h-1.44V66.11h1.44c3.86 0 4.44 3.84 4.44 6.13 0 2.28-.58 6.12-4.44 6.12zm0-18.18h-8.95v24.1h3.75v.01h5.2a12 12 0 0012.07-12.05 12 12 0 00-12.07-12.06zM218.33 84.3h-19.38l9.56-18.19h-9.56V60.2h19.38l-9.56 18.17h9.56v5.93zm11.95-9.33l2.15-6.13 2.15 6.13h-4.3zm7.57 9.32h7.01l-.02-.06-8.93-24.1h-6.94l-8.95 24.16h7l.02-.06 1.17-3.33h8.45l1.19 3.39zm-57.62-24.11l-3.1 12.13-3.1-12.13H168l-3.1 12.13-3.1-12.13h-6.12l6.16 24.1h6.12l3.06-11.96 3.05 11.97h6.12l6.16-24.1h-6.12zM302.9 69.7l-3.63-1.3a.85.85 0 01-.56-.8c0-.8.65-1.46 1.45-1.46a9.9 9.9 0 014.49 1.06l2.67-5.32a15.8 15.8 0 00-7.16-1.7 7.42 7.42 0 00-7.4 7.41 6.82 6.82 0 004.5 6.41l3.64 1.3a1.57 1.57 0 01-.53 3.04 9.9 9.9 0 01-5.9-1.93l-2.69 5.36a15.8 15.8 0 008.6 2.52 7.53 7.53 0 002.53-14.6zm-48.83-3.64h3.28a2.27 2.27 0 010 4.55h-3.28v-4.55zm6.6 18.23h6.7l-4.92-9.36c2.22-1.5 3.77-3.9 3.77-6.6 0-4.52-4.34-8.2-8.87-8.2h-10.79v24.16h7.51v-7.76h2.53l4.07 7.76z"
            ></path>
            <path
              fill="#08143F"
              d="M159.03 49.46a17.15 17.15 0 00-15.06-3.75 71.9 71.9 0 01-15.64 1.57 71.96 71.96 0 01-15.62-1.72 17.15 17.15 0 00-15.1 3.6c-2.32 2-3.63 4.29-3.65 6.7-.04 8.32 15.3 15.13 34.25 15.23 18.96.1 34.36-6.57 34.4-14.88.01-2.42-1.28-4.71-3.59-6.75z"
            ></path>
            <path
              stroke="#08143F"
              strokeMiterlimit="10"
              strokeWidth="3.83"
              d="M159.03 49.46a17.15 17.15 0 00-15.06-3.75 71.9 71.9 0 01-15.64 1.57 71.96 71.96 0 01-15.62-1.72 17.15 17.15 0 00-15.1 3.6c-2.32 2-3.63 4.29-3.65 6.7-.04 8.32 15.3 15.13 34.25 15.23 18.96.1 34.36-6.57 34.4-14.88.01-2.42-1.28-4.71-3.59-6.75z"
            ></path>
            <path
              fill="#fff"
              d="M195.56 84.29h-7.52v-24.1h7.52v24.1zm82.46-5.93h-1.44V66.11h1.44c3.86 0 4.44 3.84 4.44 6.13 0 2.28-.58 6.12-4.44 6.12zm0-18.18h-8.95v24.1h3.75v.01h5.2a12 12 0 0012.07-12.05 12 12 0 00-12.07-12.06z"
            ></path>
            <path
              stroke="#08143F"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeWidth="16"
              d="M139.98 66.11c-3.86 0-4.44 3.84-4.44 6.13s.58 6.12 4.44 6.12c3.86 0 4.44-3.83 4.44-6.12s-.58-6.13-4.44-6.13zm0 18.18c-6.2 0-10.37-4.84-10.37-12.05 0-7.21 4.17-12.05 10.37-12.05 6.2 0 10.37 4.84 10.37 12.05 0 7.2-4.17 12.05-10.37 12.05zm-21.08 0a9 9 0 01-9-9h5.93a3.07 3.07 0 103.06-3.06h-5.28l3.74-6.12h-5.97v-5.93h16.54l-4.5 7.35a9 9 0 01-4.53 16.76z"
            ></path>
            <path
              fill="#fff"
              d="M139.98 66.11c-3.86 0-4.44 3.84-4.44 6.13s.58 6.12 4.44 6.12c3.86 0 4.44-3.83 4.44-6.12s-.58-6.13-4.44-6.13zm0 18.18c-6.2 0-10.37-4.84-10.37-12.05 0-7.21 4.17-12.05 10.37-12.05 6.2 0 10.37 4.84 10.37 12.05 0 7.2-4.17 12.05-10.37 12.05zm-21.08 0a9 9 0 01-9-9h5.93a3.07 3.07 0 103.06-3.06h-5.28l3.74-6.12h-5.97v-5.93h16.54l-4.5 7.35a9 9 0 01-4.53 16.76zm99.43.01h-19.38l9.56-18.19h-9.56V60.2h19.38l-9.56 18.17h9.56v5.93zm11.95-9.33l2.15-6.13 2.15 6.13h-4.3zm7.57 9.32h7.01l-.02-.06-8.93-24.1h-6.94l-8.95 24.16h7l.02-.06 1.17-3.33h8.45l1.19 3.39zm-57.62-24.11l-3.1 12.13-3.1-12.13H168l-3.1 12.13-3.1-12.13h-6.12l6.16 24.1h6.12l3.06-11.96 3.05 11.97h6.12l6.16-24.1h-6.12zM302.9 69.7l-3.63-1.3a.85.85 0 01-.56-.8c0-.8.65-1.46 1.45-1.46a9.9 9.9 0 014.49 1.06l2.67-5.32a15.8 15.8 0 00-7.16-1.7 7.42 7.42 0 00-7.4 7.41 6.82 6.82 0 004.5 6.41l3.64 1.3a1.57 1.57 0 01-.53 3.04 9.9 9.9 0 01-5.9-1.93l-2.69 5.36a15.8 15.8 0 008.6 2.52 7.53 7.53 0 002.53-14.6zm-48.83-3.64h3.28a2.27 2.27 0 010 4.55h-3.28v-4.55zm6.6 18.23h6.7l-4.92-9.36c2.22-1.5 3.77-3.9 3.77-6.6 0-4.52-4.34-8.2-8.87-8.2h-10.79v24.16h7.51v-7.76h2.53l4.07 7.76z"
            ></path>
            <path
              stroke="#08143F"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="7.23"
              d="M140.23 29.92s-7.04-2.01-15.56-.99"
            ></path>
            <path
              stroke="#08143F"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="5.1"
              d="M131.8 22.66S123.02 18.87 119.92 3m12.49 31.77s10.91-3.99 10.95-11.33c.04-7.34-12.38-4.88-23.44-20.44"
            ></path>
            <path
              stroke="#08143F"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="7.23"
              d="M121.86 26.4s6.76-1.83 14.58-1.83c7.83 0 2.38-4.03.27-4.91-2.11-.88-7.64-3.77-10.37-7.03"
            ></path>
            <path
              fill="#3466BF"
              d="M121.86 26.4a32.71 32.71 0 019.08-2.87c1.56-.32 3.16-.44 4.77-.55.83-.06 1.5-.05 2.18-.15.33-.04.63-.1.88-.16.24-.05.4-.19.29-.1-.09.07-.19.26-.22.47-.04.18.03.3.02.29.01 0-.13-.23-.31-.42-.36-.42-.9-.85-1.46-1.23a7.43 7.43 0 00-.83-.51l-.49-.25-.55-.29c-.73-.38-1.41-.8-2.08-1.25-2.66-1.76-5.25-3.9-6.8-6.75a46.62 46.62 0 008.07 4.42l2.08.87 1.1.41A10.5 10.5 0 01141 20.6c.34.39.7.77 1 1.5.15.34.27.86.2 1.43a2.6 2.6 0 01-.76 1.47c-.7.64-1.28.78-1.8.94-.5.11-.97.2-1.4.23-.89.06-1.78.05-2.5.02-1.5-.07-3.02-.03-4.55-.03l-9.33.25z"
            ></path>
            <path
              stroke="#08143F"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="11.48"
              d="M139.72 42.73a28.43 28.43 0 01-6.05-.86c-5.05-1.28-9.5-3.6-14.75-.7"
            ></path>
            <path
              fill="#3466BF"
              d="M139.72 42.73a11.17 11.17 0 01-5.75 1.36 22.18 22.18 0 01-5.4-.91c-1.63-.45-3.05-.95-4.5-1.3-1.47-.41-3.04-.58-5.15-.72A7.37 7.37 0 01124 37.7c2.12-.44 4.22-.14 6 .36a28.5 28.5 0 014.89 1.97c1.52.76 3.1 1.54 4.84 2.7z"
            ></path>
            <path
              fill="#08143F"
              d="M142.77 32.65a46.1 46.1 0 00-23.87-2.4c-.4.06-.7.4-.71.8l-.15 3.48a.85.85 0 001 .88 41.16 41.16 0 0123.07 2.48.85.85 0 001.18-.77l.02-3.67a.85.85 0 00-.54-.8z"
            ></path>
            <path
              stroke="#08143F"
              strokeMiterlimit="10"
              strokeWidth="0.85"
              d="M142.77 32.65a46.1 46.1 0 00-23.87-2.4c-.4.06-.7.4-.71.8l-.15 3.48a.85.85 0 001 .88 41.16 41.16 0 0123.07 2.48.85.85 0 001.18-.77l.02-3.67a.85.85 0 00-.54-.8z"
            ></path>
            <path
              stroke="#B54D40"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="2.98"
              d="M120.74 32.52s2.66-.82 6.08-.48"
            ></path>
            <path
              stroke="#D8CF25"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="1.27"
              d="M137.1 33.56c.46.1 1.06.22 1.63.32"
            ></path>
            <path
              stroke="#B54D40"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="2.98"
              d="M133.15 32.92l.88.17"
            ></path>
            <path
              stroke="#D8CF25"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="1.27"
              d="M129.37 34.39l.8-4.38c.14-.7.8-1.15 1.5-1.02l6.09 1.12c.69.13 1.15.8 1.02 1.48m-.81 4.38c-.13.7-.8 1.15-1.48 1.03l-4.56-.85"
            ></path>
            <path
              stroke="#08143F"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="3.83"
              d="M162.61 56.21c.02-2.42-1.28-4.71-3.58-6.75a17.15 17.15 0 00-15.06-3.75c-4.7 1.03-10 1.6-15.64 1.57a72.13 72.13 0 01-15.62-1.72 17.15 17.15 0 00-15.1 3.6c-2.32 2-3.64 4.29-3.65 6.7"
            ></path>
            <path
              fill="#3466BF"
              d="M162.61 56.21a7.97 7.97 0 00-1.84-4.44c-1-1.27-2.3-2.29-3.65-3.14a16.9 16.9 0 00-9.07-2.5c-1.61.01-3.09.28-4.73.67a66.43 66.43 0 01-15 1.55 67.13 67.13 0 01-14.98-1.7c-1.63-.4-3.1-.68-4.71-.72a16.9 16.9 0 00-9.1 2.42 14.37 14.37 0 00-3.68 3.1 7.98 7.98 0 00-1.89 4.42 7.64 7.64 0 011.44-4.76 15.2 15.2 0 013.56-3.63c1.39-1 2.94-1.77 4.57-2.33a18.22 18.22 0 0110.2-.32 77.67 77.67 0 0014.6 1.39 77.98 77.98 0 0014.62-1.24c1.6-.34 3.4-.59 5.12-.5 3.47.1 6.87 1.27 9.63 3.3a15.2 15.2 0 013.53 3.66 7.64 7.64 0 011.38 4.77z"
            ></path>
            <path
              fill="#4785DD"
              d="M143.97 45.71a51.28 51.28 0 01-15.64 2.21c-5.28-.05-10.6-.7-15.62-2.36 5.23.67 10.41 1.07 15.63 1.09 5.2.04 10.4-.31 15.63-.94z"
            ></path>
            <path
              fill="#fff"
              d="M85.07 66.06v-5.93H70.04v24.16h15.03v-5.93h-7.52v-3.19h7.52v-5.93h-7.52v-3.18h7.52zm-43.62 9.09v-5.93h-7.52v-3.16h7.52v-5.93H26.42v24.16h15.03v-5.93h-7.52v-3.21h7.52zm54.52 3.21V60.18h-7.51v24.1h15.03v-5.92h-7.52zm-80.45 0V60.18H8v24.1h15.03v-5.92h-7.51zm43.88 5.93l8.94-24.16h-7.23l-5.37 14.5-5.37-14.5h-7.22l8.94 24.16h7.3z"
            ></path>
          </svg>
        </a>
      </p>
    </div>
    <div className="flex items-center justify-center mt-6">
      <p className="font-bold text-black-600">All rights reserved</p>
    </div>
  </footer>
);
